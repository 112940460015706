import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Box,
  useTheme,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import StyledDrawer from '../atoms/StyledDrawer';
import IconArrow from '../atoms/IconArrow';
import IconHome from '../../static/images/icon-home.svg';
import IconKey from '../../static/images/icon-key.svg';
import IconSwitch from '../../static/images/icon-switch.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'none',
    '@media screen and (max-width: 1290px)': {
      display: 'block',
    },
  },
  list: {
    paddingTop: '40px',
    '& .MuiListItem-button': {
      borderRadius: '10px',
    },
  },
  divider: {
    width: '70%',
    height: '1px',
    transform: 'translateX(-50%)',
    margin: '20px 0 20px 50%',
    backgroundColor: theme.palette.action.disabledBackground,
  },
  customerArea: {
    '& .MuiListItemIcon-root': {
      justifyContent: 'flex-end',
    },
    '& .up svg': {
      transform: 'rotate(180deg)',
      transition: '0.1s',
    },
    '& .down svg': {
      transform: 'rotate(0deg)',
      transition: '0.1s',
    },
  },
}));

const HeaderDrawerMenu = ({ open, setOpen, setCreateAccountOpen }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const renderListItem = (text, url, iconAlt, icon, color) => (
    <ListItem button component="a" href={url}>
      {icon && (
        <ListItemIcon>
          <img src={icon} alt={iconAlt} />
        </ListItemIcon>
      )}
      <ListItemText
        primary={text}
        primaryTypographyProps={{ style: { color } }}
      />
    </ListItem>
  );

  const handleCreateAccount = () => {
    setOpen(false);
    setCreateAccountOpen(true);
  };

  return (
    <StyledDrawer open={open} setOpen={setOpen} className={classes.root}>
      <List className={classes.list}>
        {renderListItem('Início', '/')}
        {renderListItem('Abrir empresa', '/abrir-empresa/')}
        {renderListItem('Trocar de contador', '/trocar-contador/')}
        {renderListItem('Soluções', '/solucoes/')}
        {renderListItem('Quanto custa?', '/quanto-custa/')}
        {renderListItem('Blog', 'https://conube.com.br/blog/')}

        <Box className={classes.divider} />

        <ListItem
          button
          onClick={() => setIsCollapseOpen(!isCollapseOpen)}
          className={classes.customerArea}
        >
          <ListItemText primary="Minha conta" />
          <ListItemIcon className={isCollapseOpen ? 'up' : 'down'}>
            <IconArrow />
          </ListItemIcon>
        </ListItem>

        <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
          {renderListItem(
            'Acessar plataforma',
            `${process.env.GATSBY_DYNAMO_LOGIN}`,

            'Ícone plataforma Conube',
            IconHome,
          )}
          {renderListItem(
            'Processo de Abertura',
            `${process.env.GATSBY_ABRIR_EMPRESA_BASE_URL}/login`,
            'Ícone plataforma de Abertura de Empresas',
            IconKey,
            theme.palette.info.main,
          )}
          {renderListItem(
            'Processo de Trocar de contador',
            'https://trocarcontador.conube.com.br/login',
            'Ícone plataforma de Trocar de Contador',
            IconSwitch,
            theme.palette.success.main,
          )}
        </Collapse>

        <ListItem button onClick={handleCreateAccount}>
          <ListItemText primary="Criar conta" />
        </ListItem>
      </List>
    </StyledDrawer>
  );
};

HeaderDrawerMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setCreateAccountOpen: PropTypes.func.isRequired,
};

export default HeaderDrawerMenu;
