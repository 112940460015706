import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Button,
  useTheme,
  makeStyles,
} from '@material-ui/core';

import StyledButton from '../atoms/StyledButton';
import TopNavLink from '../atoms/TopNavLink';
import HeaderDrawerMenu from '../molecules/HeaderDrawerMenu';
import HeaderDropdownMenu from '../molecules/HeaderDropdownMenu';
import CreateAccountDrawer from './CreateAccountDrawer';
import logo from '../../static/images/logo.svg';
import IconMobileMenu from '../../static/images/icon-mobile-menu.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'fixed',
    zIndex: 1000,
    left: '50%',
    top: 0,
    transform: 'translateX(-50%)',
    display: 'flex',
    justifyContent: 'center',
  },
  base: {
    height: '75px',
    width: '100%',
    maxWidth: '1460px',
    margin: '20px 30px 0',
    padding: '0 40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.contrastText,
    boxShadow: '0px 8px 11px rgba(0, 0, 0, 0.05)',
    borderRadius: '100px',
    position: 'relative',

    '&:before': {
      content: 'close-quote',
      width: '94%',
      position: 'absolute',
      margin: '0 auto',
      bottom: 0,
      // borderImage: `url(${HeaderBottomImage}) 2`,
      // borderBottom: '2px solid',
      padding: '1px',
      background: 'rgb(84,196,179)',
      background: `linear-gradient(90deg, rgba(84,196,179,1) 0%,
      rgba(84,172,223,1) 5%,
      rgba(142,72,152,1) 10%,
      rgba(222,106,46,1) 15%,
      rgba(187,59,74,1) 20%,
      rgba(84,196,179,1) 25%,
      rgba(84,172,223,1) 30%,
      rgba(142,72,152,1) 35%,
      rgba(222,106,46,1) 40%,
      rgba(187,59,74,1) 45%,
      rgba(84,196,179,1) 50%,
      rgba(84,172,223,1) 55%,
      rgba(142,72,152,1) 60%,
      rgba(222,106,46,1) 65%,
      rgba(187,59,74,1) 70%,
      rgba(222,106,46,1) 75%,
      rgba(84,196,179,1) 80%,
      rgba(84,172,223,1) 85%,
      rgba(142,72,152,1) 90%,
      rgba(222,106,46,1) 95%,
      rgba(187,59,74,1) 100%)`,
    },

    '@media screen and (max-width: 1290px)': {
      borderRadius: 0,
      margin: 0,
      maxWidth: '100%',
      padding: '0 30px',

      '&:before': {
        width: '100%',
        margin: '0',
      },
    },
  },
  items: {
    display: 'grid',
    gridTemplateColumns: 'min-content auto min-content',
    alignItems: 'center',
    maxWidth: '1220px',
    flexBasis: '100%',

    '@media screen and (max-width: 1290px)': {
      gridTemplateColumns: 'auto min-content',
    },
  },
  nav: {
    display: 'block',
    textAlign: 'center',
  },
  accountsButton: {
    display: 'flex',
    gap: '10px',
    textAlign: 'end',

    '& .MuiButton-label': {
      fontSize: '12px',
      textTransform: 'inherit',
    },
    '& .MuiButton-text': {
      padding: '10px',
      width: '140px',
    },
  },
  mobileMenuButton: {
    display: 'none',

    '@media screen and (max-width: 1290px)': {
      display: 'block',
    },
  },

  '@media screen and (max-width: 1290px)': {
    nav: { display: 'none' },
    accountsButton: { display: 'none' },
  },
}));

const Header = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [accountsMenuAnchor, setAccountsMenuAnchor] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [createAccountOpen, setCreateAccountOpen] = useState(false);
  const [createAccountStep, setCreateAccountStep] = useState(0);
  const [openCompanyData, setOpenCompanyData] = useState({});
  const [switchAccountantData, setSwitchAccountantData] = useState({});

  return (
    <>
      <header className={classes.root}>
        <Box className={classes.base}>
          <Box className={classes.items}>
            <a href="/">
              <img src={logo} alt="Conube" width="136px" height="66px" />
            </a>

            <nav className={classes.nav}>
              <TopNavLink
                to="/abrir-empresa/"
                backgroundColor={theme.palette.info.alpha}
                textColor={theme.palette.info.main}
                label="Abrir empresa"
              />
              <TopNavLink
                backgroundColor={theme.palette.success.alpha}
                textColor={theme.palette.success.main}
                to="/trocar-contador/"
                label="Trocar de contador"
              />
              <TopNavLink
                backgroundColor={theme.palette.primary.alpha}
                textColor={theme.palette.primary.main}
                to="/solucoes/"
                label="Soluções"
                isNew={false}
              />
              <TopNavLink
                backgroundColor={theme.palette.primary.alpha}
                textColor={theme.palette.primary.main}
                to="/quanto-custa/"
                label="Quanto custa?"
              />
              <TopNavLink
                backgroundColor={theme.palette.primary.alpha}
                textColor={theme.palette.primary.main}
                to="https://conube.com.br/blog/"
                label="Blog"
              />

            </nav>

            <Box className={classes.accountsButton}>
              <Button color="primary" onClick={(event) => setAccountsMenuAnchor(event.currentTarget)}>
                Minha conta
              </Button>

              <StyledButton
                text="Criar conta"
                variant="contained"
                color="primary"
                height="50px"
                onClick={() => setCreateAccountOpen(true)}
              />

              <HeaderDropdownMenu
                menuAnchor={accountsMenuAnchor}
                setMenuAnchor={setAccountsMenuAnchor}
              />
            </Box>

            <IconButton
              onClick={() => setDrawerOpen(true)}
              className={classes.mobileMenuButton}
            >
              <img src={IconMobileMenu} alt="Menu de opções" />
            </IconButton>
          </Box>

          <HeaderDrawerMenu
            open={drawerOpen}
            setOpen={setDrawerOpen}
            setCreateAccountOpen={setCreateAccountOpen}
          />

          <CreateAccountDrawer
            open={createAccountOpen}
            setOpen={setCreateAccountOpen}
            process="default"
            step={createAccountStep}
            setStep={setCreateAccountStep}
            openCompanyData={openCompanyData}
            setOpenCompanyData={setOpenCompanyData}
            switchAccountantData={switchAccountantData}
            setSwitchAccountantData={setSwitchAccountantData}
          />
        </Box>
      </header>
    </>
  );
};

export default Header;
